import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { api } from "@/api";
import { mainStore } from "@/utils/store-accessor";
let NovidadesModule = class NovidadesModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.novidades = [];
    }
    setNovidades(payload) {
        this.novidades = payload;
    }
    addNovidade(payload) {
        this.novidades.unshift(payload);
    }
    removeNovidade(id) {
        this.novidades = this.novidades.filter(n => n.id !== id);
    }
    updateNovidadeMutation(payload) {
        const index = this.novidades.findIndex(n => n.id === payload.id);
        if (index !== -1) {
            this.novidades.splice(index, 1, payload);
        }
    }
    async getNovidades() {
        try {
            const response = await api.getNovidades(mainStore.token);
            if (response && response.data) {
                this.setNovidades(response.data.sort((a, b) => Number(b.is_new) - Number(a.is_new)));
            }
            else {
                this.setNovidades([]);
                console.error("A resposta da API de novidades não contém dados.");
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createNovidade(payload) {
        try {
            const response = await api.createNovidade(mainStore.token, payload);
            if (response && response.data) {
                this.addNovidade(response.data);
                mainStore.addNotification({
                    content: "Novidade criada com sucesso",
                    color: "success",
                });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateNovidade(payload) {
        try {
            const response = await api.updateNovidade(mainStore.token, payload.id, payload);
            if (response && response.data) {
                this.updateNovidadeMutation(response.data);
                mainStore.addNotification({
                    content: "Novidade atualizada com sucesso",
                    color: "success",
                });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteNovidade(id) {
        try {
            await api.deleteNovidade(mainStore.token, id);
            this.removeNovidade(id);
            mainStore.addNotification({
                content: "Novidade excluída com sucesso",
                color: "success",
            });
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], NovidadesModule.prototype, "setNovidades", null);
__decorate([
    Mutation
], NovidadesModule.prototype, "addNovidade", null);
__decorate([
    Mutation
], NovidadesModule.prototype, "removeNovidade", null);
__decorate([
    Mutation
], NovidadesModule.prototype, "updateNovidadeMutation", null);
__decorate([
    Action
], NovidadesModule.prototype, "getNovidades", null);
__decorate([
    Action
], NovidadesModule.prototype, "createNovidade", null);
__decorate([
    Action
], NovidadesModule.prototype, "updateNovidade", null);
__decorate([
    Action
], NovidadesModule.prototype, "deleteNovidade", null);
NovidadesModule = __decorate([
    Module({ name: "novidades" })
], NovidadesModule);
export default NovidadesModule;
